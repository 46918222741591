<template>
	<div class="table-button-container">
		<a class="button" type="button" v-if="state.edit" @click="decreaseCount"
			:class="{ disabled: state.count == 0 }"><i class="fa-solid fa-chevron-left"></i></a>
		<span class="count">{{ state.count }}</span>
		<a class="button" type="button" v-if="state.edit" @click="increaseCount"
			:class="{ disabled: state.count == slots }"><i class="fa-solid fa-chevron-right"></i></a>

		<a type="button" v-if="!state.edit" class="edit button" @click="state.edit = !state.edit"><i
				class="fa-sharp fa-solid fa-pencil"></i></a>
		<a type="button" v-if="state.edit" class="button close" @click="closeEdit"><i
				class="fa-solid fa-circle-xmark"></i></a>
	</div>
</template>

<script setup>
import { reactive } from 'vue';

const props = defineProps({
	slots: {
		type: Number
	}
});

const state = reactive({
	count: props.slots,
	edit: false
});

function decreaseCount() {
	if (state.count > 0) {
		state.count--;
	}
}

function increaseCount() {
	if (state.count < props.slots) {
		state.count++;
	}
}

function closeEdit() {
	if (state.count == props.slots) {
		state.edit = false;
		return;
	}

	let answer = confirm("Ihre Änderungen gehen verloren. Sind Sie sicher?");
	if (answer == false) {
		return;
	}

	state.edit = !state.edit;
	state.count = props.slots;
}

</script>

<style lang="scss" scoped>
.table-button-container {
	margin: 0 1rem;

	.edit {
		margin-left: 0.5rem !important;
	}

	.close {
		margin-left: 0.5rem !important;
	}

	.button {
		margin: 0 0.25rem;

		&:hover {
			cursor: pointer;
		}
	}

	.disabled {
		color: gray;

		&:hover {
			cursor: auto !important;
		}
	}
}
</style>