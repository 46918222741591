<template>
	<div id="privacy" class="base bg-blur" ref="wrapper">
		<div class="inner">
			<div class="header">
				<h1 class="title">Datenschutzerklärung</h1>
			</div>
			<div class="content">
				<h2 class="subtitle">Angaben gemäß § 5 TMG:</h2>
				<Postal>
					Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere
					der EU-Datenschutzgrundverordnung (DSGVO), ist:</Postal>
				<h2 class="subtitle">Ihre Betroffenenrechte</h2>
				<p>
					Unter den angegebenen Kontaktdaten unseres
					Datenschutzbeauftragten können Sie jederzeit folgende Rechte
					ausüben:
				</p>
				<ul>
					<li>
						Auskunft über Ihre bei uns gespeicherten Daten und deren
						Verarbeitung (Art. 15 DSGVO),
					</li>
					<li>
						Berichtigung unrichtiger personenbezogener Daten (Art. 16
						DSGVO),
					</li>
					<li>
						Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),
					</li>
					<li>
						Einschränkung der Datenverarbeitung, sofern wir Ihre Daten
						aufgrund gesetzlicher Pflichten noch nicht löschen dürfen
						(Art. 18 DSGVO),
					</li>
					<li>
						Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art.
						21 DSGVO) und
					</li>
					<li>
						Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
						eingewilligt haben oder einen Vertrag mit uns abgeschlossen
						haben (Art. 20 DSGVO).
					</li>
				</ul>
				<p>
					Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
					jederzeit mit Wirkung für die Zukunft widerrufen.
				</p>
				<p>
					Sie können sich jederzeit mit einer Beschwerde an eine
					<a class="link" href="https://www.bfdi.bund.de/DE/Service/Kontakt/kontakt_node.html"
						target="_blank">Aufsichtsbehörden</a>
					wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands
					Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle
					zuständige Behörde.
				</p>

				<h2 class="subtitle">Nutzung der Website</h2>
				<div class="content small-pad">
					<h3 class="subtitle">Datenerhebung</h3>
					<p>
						Wenn Sie unsere Webseite lediglich als Mittel zur Information
						nutzen, also keine Registrierung und auch keine anderweitige
						Übermittlung von Informationen stattfindet, erheben wir nur die
						personenbezogenen Daten, die Ihr Browser an unseren Server
						übermittelt. Dieser wird vom Webserver automatisch im Logfile
						gespeichert und dient der Fehleranalyse. Wenn Sie unsere Website
						betrachten möchten, erheben wir daher folgenden Daten:
					</p>
					<ul>
						<li>IP-Adresse</li>
						<li>Datum und Uhrzeit des Abrufs</li>
						<li>Browsertyp</li>
						<li>Sprache und Browser-Version</li>
						<li>URL des Abrufs</li>
						<li>Zeitzone</li>
						<li>Zugriffsstatus/HTTP-Statuscode</li>
						<li>Datenmenge (in KB)</li>
						<li>Websites, von denen die Anforderung kommt (Referer)</li>
						<li>Betriebssystem</li>
					</ul>
					<p>
						Eine Speicherung dieser Daten zusammen mit anderen
						personenbezogenen Daten von Ihnen findet nicht statt.
					</p>

					<h3 class="subtitle">Kartenreservierung</h3>
					<p>
						Sollte die Kartenreservierung in Anspruch genommen werden, so
						werden Ihre Personenbezogenen Daten (Anrede, Vorname, Nachname,
						Email) zum Zweck der vorläufigen Reservierung und dem
						anschließenden Erwerb der Karten gespeichert. Eine Woche nach
						Veranstaltungsende werden Ihre Daten wieder gelöscht.
					</p>

					<h3 class="subtitle">Newsletter</h3>
					<p>
						Für die Zustellung unseres Newsletters erheben wir
						personenbezogene Daten, die über eine Eingabemaske an uns
						übermittelt werden. Für eine wirksame Registrierung benötigen wir
						eine valide E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung
						tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt,
						setzen wir das „Double-Opt-in“-Verfahren ein. Hierzu
						protokollieren wir die Anmeldung zum Newsletter, den Versand
						einer Bestätigungsmail und den Eingang der hiermit angeforderten
						Antwort. Weitere Daten werden nicht erhoben. Auf Grundlage Ihrer
						ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1 lit. a DSGVO),
						übersenden wir Ihnen regelmäßig unseren Newsletter bzw.
						vergleichbare Informationen per E-Mail an Ihre angegebene
						E-Mail-Adresse. Die Einwilligung zur Speicherung Ihrer
						persönlichen Daten und ihrer Nutzung für den Newsletterversand
						können Sie jederzeit mit Wirkung für die Zukunft widerrufen. In
						jedem Newsletter findet sich dazu ein entsprechender Link.
						Außerdem können Sie uns jederzeit Ihren Widerruf über die am Ende
						dieser Datenschutzhinweise angegebene Kontaktmöglichkeit
						mitteilen.
					</p>
					<p>
						Die Daten werden in diesem Zusammenhang nur verarbeitet, solange
						die entsprechende Einwilligung vorliegt. Danach werden sie
						gelöscht. Die Bereitstellung Ihrer personenbezogenen Daten
						erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne
						bestehende Einwilligung können wir Ihnen unseren Newsletter
						leider nicht zusenden. Die Einwilligung zur Speicherung Ihrer
						persönlichen Daten und ihrer Nutzung für den Newsletterversand
						können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Die
						Abmeldung kann über den in jeder E-Mail enthaltenen Link oder
						beim unten aufgeführten Datenschutzbeauftragten bzw. der für den
						Datenschutz zuständigen Person beantragt werden.
					</p>

					<h3 class="subtitle">SSL-Verschlüsselung</h3>
					<p>
						Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
						verwenden wir dem aktuellen Stand der Technik entsprechende
						Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
					</p>
				</div>

				<h2 class="subtitle">Foto- und Videoaufnahmen auf der Veranstaltung</h2>
				<div class="content small-pad">
					<p>
						Bilder, die wir durch einen professionellen Fotographen auf dem FMI-Ball erstellen lassen, werden zur
						Öffentlichkeitsarbeit verwendet. Dies schließt unter anderem die Nutzung in gängigen sozialen Medien
						mit
						ein. Sie dienen außerdem der dauerhaften Dokumentation des FMI-Balls, sowohl offline als auch online
						und
						können daher auch im außereuropäischen Ausland abgerufen werden.
					</p>
					<p>
						Die Verarbeitung dieser Bilder durch uns und den Fotographen dient der Wahrung unseres berechtigten
						Interesses an
						Öffentlichkeitsarbeit
						und Dokumentation im Sinne von Art. 6 Abs. 1 f) DSGVO.
					</p>
				</div>

				<h2 class="subtitle">Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
				<p>
					Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
					Situation ergeben, jederzeit gegen die Verarbeitung Sie
					betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1
					lit. f DSGVO (Datenverarbeitung auf der Grundlage einer
					Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt
					auch für ein auf diese Bestimmung gestütztes Profiling im Sinne
					von Art. 4 Nr. 4 DSGVO.
				</p>
				<p>
					Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen
					Daten nicht mehr verarbeiten, es sei denn, wir können zwingende
					schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
					Interessen, Rechte und Freiheiten überwiegen, oder die
					Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
					von Rechtsansprüchen.
				</p>
				<p>
					Sollten sie Wiederspruch erheben wollen, wenden sie sich bitte an
					<a class="link" href="mailto:fmi-ball@uni-jena.de">fmi-ball@uni-jena.de</a>
				</p>

				<h2 class="subtitle">Änderung unserer Datenschutzbestimmungen</h2>
				<p>
					Wir behalten uns vor, diese Datenschutzerklärung anzupassen,
					damit sie stets den aktuellen rechtlichen Anforderungen
					entspricht oder um Änderungen unserer Leistungen in der
					Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer
					Services. Für Ihren erneuten Besuch gilt dann die neue
					Datenschutzerklärung.
				</p>

				<h2 class="subtitle">Fragen an den Datenschutzbeauftragten</h2>
				<p>
					Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte
					eine E-Mail an
					<a class="link" href="mailto:fmi-ball@uni-jena.de">fmi-ball@uni-jena.de</a>.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, reactive, ref } from "@vue/runtime-core";
import Postal from "@/components/Postal.vue";

export default {
	components: { Postal },
	setup() {
		const wrapper = ref(0);

		return {
			wrapper,
		};
	},
};
</script>

<style lang="scss" scoped>
#privacy {
	padding-top: 4rem;

	text-align: left;

	.subtitle {
		font-style: italic;
		text-decoration: underline;
	}

	.title {
		@media screen and (max-width: 780px) {
			font-size: 8vw;
		}
	}

	h3 {
		font-size: 1.4rem;
	}
}
</style>