<template>
	<div id="home" class="base" ref="wrapper">
		<div class="inner">
			<div class="header">
				<div class="title-box">
					<div class="title-top">
						<a href="#about" class="arrow">Mystery of
							<br /> Mister<span class="desktop-hidden">&nbsp;χ</span>
						</a>
					</div>
					<div class="title-bottom">
						<a>Eine Studie in <br /><span style="color: #00798a">FMI-Blau</span></a>
					</div>
				</div>
				<a href="#about" class="arrow">
					<i class="
							arrow-icon
							fa-solid fa-chevron-down fa-2x
							animate__animated
							animate__bounce
							animate__slow
							animate__infinite
						"></i>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, ref, watchEffect } from "@vue/runtime-core";
import { useRoute } from "vue-router";

export default {
	setup() {
		const route = useRoute();
		const wrapper = ref(0);

		onMounted(() => {
			watchEffect(() => {
				route.name;
				wrapper.value.scrollIntoView();
			});
		});

		return {
			wrapper,
		};
	},
};
</script>

<style lang="scss" scoped>
#home {
	height: 100vh;
	margin: 0;
	color: $white;
	transform: translateY(-4.4rem);

	.inner {
		width: auto;
		padding: 2rem;
		padding-top: 50vh;

		@media screen and (max-width: 780px) {
			padding-top: 2rem;
		}

		.header {
			padding: 0;

			.infos {
				text-shadow: 1px 1px 6px black;
				padding-right: 1.5rem;

				font-size: 2rem;

				@media screen and (min-width: 780px) {
					font-size: 5vw;
				}
			}

			.title-box {
				position: relative;

				@media screen and (min-width: 780px) {
					position: absolute;
					top: 30%;
					left: 26%
				}

				display: flex;
				flex-direction: column;
				align-items: flex-start;

				@media screen and (max-width: 779px) {
					* {
						display: inline;
					}

					br {
						display: none;
					}
				}

				.title-top {
					font-family: "Source Code Pro", monospace;
					font-weight: bold;
					letter-spacing: -0.1rem;
					line-height: 100%;
					font-size: 2rem;

					@media screen and (min-width: 780px) {
						font-size: 4rem;
					}
				}

				.title-bottom {
					font-family: "Roboto", sans-serif;
					font-weight: bold;
					line-height: 100%;
					font-size: 1.5rem;

					@media screen and (min-width: 780px) {
						font-size: 3rem;
					}

					@media screen and (min-width: 780px) {
						margin-left: 1rem;
					}
				}

				.desktop-hidden {
					@media screen and (min-width: 780px) {
						display: none;
					}
				}

				div {
					margin-bottom: 1rem;
					width: 100%;

					@media screen and (min-width: 780px) {
						width: max-content;
					}

					text-align: center;
					text-shadow: 2px 2px 3px black;

					@media screen and (min-width: 780px) {
						text-align: right;
					}
				}

				a {
					padding: 0;
				}
			}
		}

		.arrow {
			display: flex;
			flex-direction: column;
			padding: 1rem;
			text-decoration: none;
			color: $white;
			filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));

			&:hover {
				cursor: pointer;
			}

			.fa-2x {
				font-size: 2rem;

				@media screen and (min-width: 780px) {
					font-size: 5vw;
				}
			}
		}
	}

	.content {
		font-size: 1.2rem;
		background: $white-second;
		padding: 1rem 5rem 3rem 5rem;
		text-align: left;
	}
}
</style>