<template>
	<div v-if="!route.query['token']">
		<Reservation />
	</div>
	<div v-if="route.query['token']">
		<Management />
	</div>
</template>

<script>
import { useRoute } from 'vue-router';
import Reservation from "@/components/tickets/Reservation.vue";
import Management from "@/components/tickets/Management.vue";

export default {
	components: { Reservation, Management },
	setup () {
		

		return {
			route: useRoute()
		}
	}
}
</script>

<style lang="scss" scoped>

</style>