<template>
	<div id="tickets" class="base bg-blur">
		<div class="inner">
			<div class="header">
				<h2 class="title">Kartenverkauf und Abholung</h2>
				<p class="subtitle">
					Es sind noch Tickets erhältlich!
				</p>

				<!-- <FancyButton v-if="true" link="Tickets" text="Jetzt reservieren" @click="" /> -->
			</div>
			<div class="content">
				<p>
					Sie haben den Verkauf verpasst? Kein Problem, es sind noch Restkarten verfügbar! Jeweils vor und nach
					unserem kostenlosen Tanzkurs können Sie noch Tickets erwerben, dazu müssen Sie auch nicht am Tanzkurs
					selber teilnehmen.
				</p>
				<p>Verkaufsstellen und Termine:</p>
				<table class="list">
					<tr>
						<td>03. Juni, Montag:</td>
						<td>HS5, Carl-Zeiss-Straße 3</td>
						<td>16 Uhr oder 18 Uhr</td>
					</tr>
					<tr>
						<td>05. Juni, Mittwoch:</td>
						<td>HS5, Carl-Zeiss-Straße 3</td>
						<td>16 Uhr oder 18 Uhr</td>
					</tr>
				</table>
				<p>Preise:</p>
				<ul class="list">
					<li>Stuhlplatz: 17€</li>
					<li>Tischplatz: 22€</li>
				</ul>
				<p>
					Sollte Ihnen einer dieser beiden Termine nicht passen, können Sie uns auch gerne unter <a class="link"
						href="mailto:fmi-ball@uni-jena.de">fmi-ball@uni-jena.de</a> kontaktieren und wir finden einen
					alternativen Weg.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import FancyButton from "../FancyButton.vue";
export default {
	components: { FancyButton },
	setup() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.list {
	list-style: none;
}

ul {
	padding-left: 1.5rem;
}

table {
	padding-left: 0.5rem;
	border-collapse: separate;
	border-spacing: 1rem 0;
}

@media only screen and (max-width: 700px) {

	/* Force table to not be like tables anymore */
	table,
	td,
	tr {
		display: block;
	}

	tr {
		border: 1px solid #ccc;
		margin-bottom: 0.5rem;
		padding: 0.4rem;
	}

	td {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
	}

	td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}
}
</style>