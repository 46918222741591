<template>
	<div id="pictures" class="base bg-blur">
		<div class="inner">
			<div class="header">
				<h2 class="title">Informationen zu Fotoaufnahmen</h2>

				<FancyButton href="#tickets" text="Karten kaufen" v-if="false" />
			</div>

			<div class="content">
				<p></p>
				<h3>Fotoaufnahmen auf der Veranstaltung</h3>
				<p>
					Um den Ball für euch und uns unvergesslich zu machen, haben wir einen professionellen Fotographen
					engagiert der die besten Momente des Balls in Bildformat für die Ewigkeit festhalten wird.
				</p>
				<p>
					Die Bilder werden über einen Link für alle Teilnehmenden des Balles verfügbar sein. Außerdem
					veröffentlicht der Fotograph gegebenenfalls einige dieser Bilder in seinem Portfolio. Wenn ihr euch
					damit nicht wohl fühlt, verstehen wir das natürlich. Meldet euch dann bitte einfach bei uns unter <a
						class="link" href="mailto:fmi-ball@uni-jena.de">fmi-ball@uni-jena.de</a> oder dem Fotographen direkt,
					sodass die betroffenen Bilder entfernt werden können.
				</p>
				<p>
					Alle rechtlichen Grundlagen könnt ihr auf unserer <a class="link" href="datenschutz">Datenschutzseite</a>
					nachlesen.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { useRouter } from "vue-router";
import FancyButton from "../FancyButton.vue";
export default {
	components: { FancyButton },
	setup() {
		const router = useRouter();

		function gotoTickets() {
			router.push({ name: "Tickets" });
		}

		return {
			gotoTickets,
		};
	},
};
</script>

<style lang="scss" scoped></style>