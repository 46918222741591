import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/content/Home.vue';
import Impressum from '../views/Impressum.vue';
import Privacy from '../views/Privacy.vue';
import Tickets from '../views/Tickets.vue';
import Archive from '../views/Archive.vue';


const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: "FMI-Ball 2024"
		}
	},
	//   {
	//     path: '/archive',
	//     name: 'Archive',
	//     component: Archive,
	//     meta: {
	//       title: "FMI-Ball Archiv"
	//     }
	//   },
	{
		path: '/karten',
		redirect: '#tickets',
		name: 'Tickets',
		component: Tickets,
		meta: {
			title: "Karten | FMI-Ball"
		}
	},
	{
		path: '/impressum',
		name: 'Impressum',
		component: Impressum,
		meta: {
			title: "FMI-Ball Impressum"
		}
	},
	{
		path: '/datenschutz',
		name: 'Privacy',
		component: Privacy,
		meta: {
			title: "FMI-Ball Datenschutz"
		}
	},
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		redirect: { name: 'Home' }
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return { el: to.hash };

		}
	},
});

router.beforeEach((to, from) => {
	document.getElementById("top").scrollIntoView();
});

router.afterEach((to, from) => {
	document.title = to.meta.title;

	console.log(to.name);
});

export default router;
