<template>
	<div id="management-container">
		<div class="important-wrapper">
			<div class="tip-wrapper">
				<p>
					Folgende Plätze sind für Sie Reserviert:
				</p>
				<table>
					<tr v-for="(table, index) in data.tables" :key="index" class="table_bar">
						<td v-if="table.table_id != 0">Tisch {{ table.table_id }}</td>
						<td v-if="table.table_id == 0">Stuhl</td>
						<td>
							<TicketButton :slots="table.count"></TicketButton>
						</td>
					</tr>
				</table>
				<!-- <button :disabled="">Speichern</button> -->
				<!-- <button :disabled="">Abbrechen</button> -->
			</div>
		</div>
	</div>
</template>

<script>
import { useRoute } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { reactive } from "@vue/reactivity";
import TicketButton from "@/components/tickets/TicketButton.vue";

import rs from "@/services/RequestService.js";

export default {
	components: { TicketButton },
	setup() {
		const route = useRoute();
		let token = route.query["token"];

		const data = reactive({
			tables: null
		});

		onMounted(() => {
			loadData();
		});

		async function loadData() {
			let answer = await rs.get("user", {
				token: token,
			});

			data.tables = answer.data.message;
		}

		return {
			data
		};
	},
};
</script>

<style lang="scss" scoped>
#management-container {
	background-color: $white;
	width: calc(15rem + (8 * 2rem + 5rem) * 3);

	padding: 3rem 5rem;
	margin: 5rem auto 0 auto;

	.important-wrapper {
		display: flex;

		margin: auto;
		width: max-content;

		.tip-wrapper {
			width: 30rem;
			text-align: justify;

			p {
				margin: 0;
			}
		}
	}

	button {
		border: 1px solid $black;
		border-radius: 10rem;
		text-align: center;
		width: max-content;
		font-size: 1rem;

		padding: 0.5rem 2rem;
		outline: none;
		transition: all 0.1s;

		&:hover {
			cursor: pointer;
		}

		&:disabled {
			cursor: default;
		}
	}
}
</style>