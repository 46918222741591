<template>
	<div id="about" class="base bg-blur">
		<div class="inner">
			<div class="header">
				<h2 class="title">Save the Date!</h2>
				<p class="subtitle">
					Der nächste FMI-Ball findet am <u>08.06.2024</u> statt
				</p>

				<FancyButton href="#tickets" text="Karten kaufen" v-if="false" />
			</div>

			<div class="content">
				<p></p>
				<h3>Der Ball der Fakultät für Mathematik und Informatik</h3>
				<p>
					Der FMI-Ball wird vom Formel e.V. geplant und durchgeführt und von den Fachschaftsräten Mathematik,
					Informatik sowie Bioinformatik unterstützt. Nach dem Erfolgsschlager <i>Casino Binomial</i> im Jahr 2022
					und der Reise durch die Zeit beim <i>Ball to the future</i> im letzten Jahr ist er nun schon der dritte
					Ball seiner Art und baut so den Weg zur Tradition weiter aus. Das Motto in diesem Jahr lautet <i>Mystery
						of Mister χ [chi] - Eine Studie in FMI-Blau</i>, lassen Sie sich überraschen!
				</p>
				<p>
					Der FMI-Ball soll einen klassischen Tanzabend mit den Vorzügen künstlerischer Unterhaltung im Stile von
					Theater, Kabarett und Tanz kombinieren und dadurch einen unvergesslichen Abend für Studierende, Lehrende
					sowie Mitarbeitende der Fakultät bieten. Natürlich sind auch Angehörige anderer Fakultäten willkommen.
				</p>
				<p>
					Der Abend selbst wird durch ein studentisch durchgeführtes Bühnenprogramm, das sich an unserem Thema
					orientieren wird, begonnen und geht in eine Tanzveranstaltung über.
				</p>
				<p></p>
				<h3>Das Wichtigste in Kürze</h3>
				<p>
					Wann: 08.06.2024 <br />
					&emsp;Einlass: 18:30 Uhr<br />
					&emsp;Bühnenstück: 19 Uhr<br />
					Wo:
					<a class="link" href="https://goo.gl/maps/SiDaDed88hqd3F5E9" target="_blank">Volksbad Jena</a>
				</p>
				<p>
					Für Fragen und Anregungen zum FMI-Ball 2024 stehen wir Ihnen sehr gerne zu Verfügung. Senden Sie uns
					einfach eine E-Mail an: <a class="link" href="mailto:fmi-ball@uni-jena.de">fmi-ball@uni-jena.de</a>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { useRouter } from "vue-router";
import FancyButton from "../FancyButton.vue";
export default {
	components: { FancyButton },
	setup() {
		const router = useRouter();

		function gotoTickets() {
			router.push({ name: "Tickets" });
		}

		return {
			gotoTickets,
		};
	},
};
</script>

<style lang="scss" scoped></style>